export default {
    state: {
      CycleList: [], 
      menu: [
        {
          name: "Home",
          icon: "mdi-home",
          href: "/financialclose/home",
          right: 'financialclose_project'
        },
        {
          name: "Cycles",
          icon: "mdi-sync",
          items: [] 
        },
        {
          name: "FC Masters",
          icon: "mdi-book-open-page-variant",
          items: [
            {
                name: "Chart of Accounts",
                href: '/financialclose/manage/chartofaccount',
                right: "financialclose_master_chartofaccount",
                icon: "mdi-chart-bar"
            },
            {
                name: "Tasklist",
                href: "/financialclose/manage/checklist",
                icon: "mdi-format-list-checks",
                right: "financialclose_master_checklist"
            },
            {
                name: "Information Manager",
                href: "/financialclose/manage/informationmanager",
                icon: "mdi-information",
                right: "financialclose_master_informationmanager"
            },
            {
                name: "Location",
                href: "/financialclose/manage/location",
                icon: "mdi-map-marker",
                right: "financialclose_master_checklist",
                edit:true
            },
            {
                name: "Threshold",
                href: '/financialclose/manage/threshold',
                right: "financialclose_master_checklist",
                icon: "mdi-chart-line"
            }
          ]
        }
      ]
    },
    mutations: {
      setNewList(state, d) {  
        if (d) {
          console.log("qawsertyujik",d);
          state.CycleList = d.map((item) => ({
            
            name: item.description,
            _id:  item._id,
            items: [
              {
                name: "Dashboard",
                href: `/financialclose/project/dashboard/${item._id}`,
                icon: ""
              },
              {
                name: "Tasklist",
                href: `/financialclose/project/checklist/${item._id}`,
                icon: ""
              },
              {
                name: "Information Manager",
                href: `/financialclose/project/informationmanager/${item._id}`,
                icon: ""
              },
              {
                name: "Trial Balance",
                href: `/financialclose/project/trialbalance/${item._id}`,
                icon: ""
              },
              {
                name: "Users",
                href: `/financialclose/project/users/${item._id}`,
                icon: ""
              },
              {
                name: "Threshold",
                href: `/financialclose/project/threshold/${item._id}`,
                icon: ""
              },
              {
                name: "Automation Agent",
                icon: "",
                items: [
                  {
                    name: "Transaction Matching",
                    href: `/financialclose/automationtransactionmatching/${item._id}`,
                    icon: ""
                  },
                  {
                    name: "Lease Schedule",
                    href: `/financialclose/automationlease/${item._id}`,
                    icon: ""
                  },
                  {
                    name: "Borrowing Schedule",
                    href: `/financialclose/automationborrowingschedule/${item._id}`,
                    icon: ""
                  }
                ]
              },
              {
                name: "Bolt On Modules",
                icon: "",
                items: [
                  {
                    name: "Virtual Concierge",
                    href: `/financialclose/functionalsupport/${item._id}`,
                    icon: ""
                  },
                  {
                    name: "Variance Analysis",
                    href: `/financialclose/project/varianceanalysis/${item._id}`,
                    icon: ""
                  },
                  {
                    name: "The Variance Analysis",
                    icon: "",
                    items: [
                      
                        {
                          name: "Analysis",
                          href: `/financialclose/project/${item._id}/varianceanalysis/analysis/$$vid$$`,
                          icon: ""
                        },
                        {
                          name: "Config",
                          href: `/financialclose/project/${item._id}/varianceanalysis/configuration/$$vid$$`,
                          icon: ""
                        },
                        {
                          name: "Users",
                          href: `/financialclose/project/${item._id}/varianceanalysis/users/$$vid$$`,
                          icon: ""
                        },
                        
                      
                    ]
                  }
                ]
              }
            ]
          }));
  
          // Update the Cycles menu item with the new CycleList
          const cyclesMenuItem = state.menu.find(item => item.name === "Cycles");
          if (cyclesMenuItem) {
            cyclesMenuItem.items = state.CycleList;
          }
        }
      },

      addItemToCycleList(state, item) {
        const existingItem = state.CycleList.find(olditem => olditem.name === item.description);
        
        if (!existingItem) {
          // Add the new item
          const newCycleItem = {
            name: item.description,
            _id : item._id,
            items: [
              {
                name: "Dashboard",
                href: `/financialclose/project/dashboard/${item._id}`,
                icon: ""
              },
              {
                name: "Tasklist",
                href: `/financialclose/project/checklist/${item._id}`,
                icon: ""
              },
              {
                name: "Information Manager",
                href: `/financialclose/project/informationmanager/${item._id}`,
                icon: ""
              },
              {
                name: "Trial Balance",
                href: `/financialclose/project/trialbalance/${item._id}`,
                icon: ""
              },
              {
                name: "Users",
                href: `/financialclose/project/users/${item._id}`,
                icon: ""
              },
              {
                name: "Threshold",
                href: `/financialclose/project/threshold/${item._id}`,
                icon: ""
              },
              {
                name: "Automation Agent",
                icon: "",
                items: [
                  {
                    name: "Transaction Matching",
                    href: `/financialclose/automationtransactionmatching/${item._id}`,
                    icon: ""
                  },
                  {
                    name: "Lease Schedule",
                    href: `/financialclose/automationlease/${item._id}`,
                    icon: ""
                  },
                  {
                    name: "Borrowing Schedule",
                    href: `/financialclose/automationborrowingschedule/${item._id}`,
                    icon: ""
                  }
                ]
              },
              {
                name: "Bolt On Modules",
                icon: "",
                items: [
                  {
                    name: "Virtual Concierge",
                    href: `/financialclose/functionalsupport/${item._id}`,
                    icon: ""
                  },
                  {
                    name: "Variance Analysis",
                    href: `/financialclose/project/varianceanalysis/${item._id}`,
                    icon: ""
                  },
                  {
                    name: "The Variance Analysis",
                    icon: "",
                    items: [
                      
                        {
                          name: "Analysis",
                          href: `/financialclose/project/${item._id}/varianceanalysis/analysis/$$vid$$`,
                          icon: ""
                        },
                        {
                          name: "Config",
                          href: `/financialclose/project/${item._id}/varianceanalysis/configuration/$$vid$$`,
                          icon: ""
                        },
                        {
                          name: "Users",
                          href: `/financialclose/project/${item._id}/varianceanalysis/users/$$vid$$`,
                          icon: ""
                        },
                        
                      
                    ]
                  }
                ]
              }
            ]
          };
          
          state.CycleList.push(newCycleItem);
    
          // Update the Cycles menu item in state.menu
          const cyclesMenuItem = state.menu.find(item => item.name === "Cycles");
          if (cyclesMenuItem) {
            cyclesMenuItem.items = state.CycleList;
          }
        }
      }
    },
    getters: {
      getMenu: (state) => state.menu
    }
  };